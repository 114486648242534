/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--black-color-1: #03151C;
	--black-color-2: #000;
	--gray-color-1: #BECCD4;
	--gray-color-2: #B0C5CE;
	--white-color: #FFFFFF;
	--light-blue: #15A4DC;
	--facebook-color: #1877F2;
	--instagram-color: #DD2A7B;
	--linkedin-color: #0072B1;
	--pinterest-color: #BD081C;
	--youtube-color: #C4302B;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: .5rem 1.8rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*      NAVBAR      */
/********************/

.container-navbar {
	width: 100%;
	top: 0;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	transition: all 0.3s ease;
	background-color: var(--light-blue);
}

.contain-navbar {
	background-color: var(--white-color);
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: .3rem 12rem;
	align-items: center;
}

.contain-upper-navbar {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: .3rem 4rem;
}

.contain-navbar-pc-off {
	display: none;
}

.container-uppernav-info,
.container-uppernav-social {
	display: flex;
	align-items: center;
	gap: 1rem;
	color: var(--white-color);
}

.call-now {
	text-transform: uppercase;
}

.uppernav-info-link {
	display: flex;
	gap: .5rem;
	color: var(--white-color);
	transition: all .5s ease;
	align-items: center;
}

.uppernav-info-link:hover {
	color: var(--black-color);
}

.upper-nav-social {
	display: flex;
	gap: 1rem;
}

.navbar-social-buttons {
	border: 1px solid var(--white-color);
	padding: .3rem;
	border-radius: 50%;
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	color: var(--white-color);
	transition: all .5s ease;
}

.navbar-social-buttons:hover {
	background-color: var(--white-color);
}

.social-nav-icon {
	transition: all .5s ease;
}

.facebook:hover .social-nav-icon {
	color: var(--facebook-color) !important;
}

.instagram:hover .social-nav-icon {
	color: var(--instagram-color) !important;
}

.pinterest:hover .social-nav-icon {
	color: var(--pinterest-color) !important;
}

.twitter:hover .social-nav-icon {
	color: var(--black-color-1) !important;
}

.container-logo {
	width: 6rem;
}

.container-logo img {
	width: 100%;
}

.container-navlinks {
	display: flex;
	height: 2rem;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	font-weight: 600;
	font-size: 1.05rem;
}

.btn-navbar {
	transition: all 0.6s ease;
	color: var(--black-color-1);
	position: relative;
}

.btn-navbar::after {
	content: '';
    background-color: var(--light-blue);
    height: 2px;
    width: 0;
    position: absolute;
    bottom: -.4rem;
    transition: all .5s ease;
    left: 50%;
}

.btn-navbar:hover {
	color: var(--light-blue);
}

.btn-navbar:hover::after {
	left: 0;
    width: 100%;
}

.active {
	color: var(--light-blue);
}

.active.btn-navbar::after {
	left: 0;
	width: 100%;
}

#menu-toggle {
	display: none;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.line {
	background-color: var(--light-blue);
	display: block;
	width: 30px;
	height: 2px;
	margin: 5px 0;
	border-radius: 50%;
	transition: all .3s ease-out;
}

.line-1 {
	transform: rotate(45deg) translate(0.6rem);
}

.line-2 {
	opacity: 0;
}

.line-3 {
	transform: rotate(-45deg) translate(0.6rem);
}

/********************/
/*      FOOTER      */
/********************/
.container-footer {
	display: flex;
	flex-direction: column;
	background-color: var(--white-color);
	padding-top: 2rem;
	gap: 1.5rem;
	width: 100%;
	border-top: 1px solid var(--gray-color-1);
}

.footer-dividers {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	padding: 0 8rem;
	justify-items: center;
}

.contain-logo-footer {
	width: 15rem;
	display: flex;
	align-items: flex-start;
}

.contact-title-footer {
	color: var(--black-color-1);
	font-size: 1.1rem;
}

.contain-logo-footer img {
	width: 100%;
}

.footer-divider {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}


.container-privacy {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.privacy-links {
	color: var(--black-color-1);
	font-weight: 500;
	transition: all .5s ease;
}

.privacy-links:hover {
	color: var(--light-blue);
}

.footer-contact {
	display: flex;
	flex-direction: column;
	color: var(--blue-color);
	gap: .8rem;
	font-size: 1.1rem;
}

.contact-text {
	display: flex;
	gap: .8rem;
}

.footer-copy-media {
	background: var(--gray-color-1);
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: .5rem 8rem;
	align-items: center;
	justify-items: center;
}

/* Copy */
.footer-copy {
	display: flex;
	justify-content: center;
	color: var(--black-color-1);
	width: 100%;
	padding: 1.2rem 0;
	font-weight: 500;
}

.link-to-kching {
	color: var(--black-color-1);
	font-weight: 600;
}

.footer-social-media {
	display: flex;
	gap: .5rem;
	font-size: 2rem;
	justify-content: flex-start;
	align-items: center;
}

.contain-social-btn {
	display: inline-block;
	border-radius: 2.8rem;
	height: 3rem;
	width: 3rem;
	background-color: var(--white-color);
	cursor: pointer;
	float: left;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
	transition: all .3s ease-out;
	overflow: hidden;
}

.social-btn {
	display: inline-block;
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	box-sizing: border-box;
	border-radius: 2.8rem;
	text-align: center;
	transition: all .3s ease;
}

.contain-social-btn:hover {
	width: 9rem;
}

.social-text {
	vertical-align: top;
	font-size: .9rem;
	font-weight: 500;
	color: var(--blue-color);
	line-height: 3rem;
	transition: all .3s ease;
}

.container-footer-media {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.contain-footer-media {
	display: flex;
	gap: 1rem;
}

.social-btn-icon {
	font-size: 1.5rem;
	color: var(--black-color-1);
	transition: all .3s ease;
	display: flex;
	align-items: center;
	border-radius: 50%;
	padding: .4rem;
	border: 2px solid var(--black-color-1);
}

.facebook:hover.social-btn-icon {
	color: var(--facebook-color) !important;
}

.instagram:hover.social-btn-icon {
	color: var(--instagram-color) !important;
}

.pinterest:hover.social-btn-icon {
	color: var(--pinterest-color) !important;
}

/********************/
/*   PHONE-SCREEN   */
/********************/
@media screen and (max-width: 1400px) {
	/* Navbar */
	.contain-upper-navbar {
		display: none;
	}

	.contain-navbar {
		padding: .3rem 3rem;
		z-index: 100;
		position: fixed;
	}

	#menu-toggle {
		display: block;
	}

	.container-navlinks {
		display: none;
	}

	.btn-navbar::after {
	    background-color: var(--white-color);
	}

	.btn-navbar:hover {
		color: var(--white-color);
	}
	.active {
		color: var(--white-color);
	}

	.contain-navbar-pc-off {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-items: center;
		justify-content: center;
	}

	.navbar-pc-off {
		display: flex;
		gap: 1rem;
		align-items: center;
	}

	.container-logo {
		width: 5rem;
	}

	.navbar {
		display: flex;
		background-color: var(--light-blue);
		flex-direction: column;
		position: absolute;
		left: -500px;
		top: 76px;
		height: 99vh;
		width: 70%;
		align-items: center;
		padding: 2rem 0;
		transition: all .5s ease;
		visibility: hidden;
		z-index: 5;
	}

	.navbar.menu-active {
		visibility: visible;
		transform: translateX(500px);
	}

	/* FOOTER */
	.container-footer {
		width: 100%;
	}

	.contain-logo-footer {
		align-items: center;
		justify-content: center;
	}

	.footer-social-media {
		gap: .8rem;
	}

	.container-privacy {
		width: 100%;
		gap: 1rem;
		text-align: center;
	}

	.footer-dividers {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		padding: 0 2rem;
		align-items: center;
	}

	.footer-contact {
		justify-content: center;
		align-items: center;
	}

	.footer-copy-media {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		text-align: center;
		padding: 1rem 2rem;
	}
}