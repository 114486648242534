/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--black-color-1: #03151C;
	--black-color-2: #000;
	--gray-color-1: #BECCD4;
	--gray-color-2: #B0C5CE;
	--white-color: #FFFFFF;
	--light-blue: #15A4DC;
	--facebook-color: #1877F2;
	--instagram-color: #DD2A7B;
	--linkedin-color: #0072B1;
	--pinterest-color: #BD081C;
	--youtube-color: #C4302B;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: .5rem 1.8rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

.large-button {
	width: 13rem !important;
}

/********************/
/*       HOME       */
/********************/
/*Titles*/
.uppertitle-home {
	font-size: 2.2rem;
	font-weight: 500;
	color: var(--black-color-1);
}

/* Banner */
.container-banner-home {
	display: flex;
	background: var(--white-color);
	background-position: center;
	background-size: cover;
	height: 60vh;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.contain-banner-home {
	display: flex;
	flex-direction: column;
	width: 25rem;
	gap: 2rem;
}

.banner-home-title {
	font-size: 2.5rem;
	color: var(--white-color);
}

.contain-banner-home p {
	font-size: 1.2rem;
	color: var(--white-color);
}

/* Steps */
.container-home-steps {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 8rem 0;
	justify-content: center;
	align-items: center;
}

.container-uppertitle-home {
	text-transform: uppercase;
	font-size: .9rem;
	color: var(--blue-color-2);
	font-weight: 400;
}

.home-steps-title {
	font-size: 2.2rem;
	color: var(--blue-color);
}

.home-step-text {
	max-width: 35rem;
	text-align: center;
}

.container-items {
	display: flex;
	gap: 3rem;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.steps-items {
	border-radius: .5rem;
	width: 15rem;
	padding: 2rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	transition: all .5s ease;
}

.steps-item-img {
	width: 7rem;
	height: 7rem;
	padding: 1rem;
	border-radius: 50%;
	transition: all .5s ease;
	border: 1px solid var(--gray-color-1);
}

.steps-items:hover .steps-item-img {
	background-color: var(--light-blue);
}

.steps-item-img img {
	width: 100%;
}

.steps-item-text {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	text-align: center;
	height: 4.5rem;
	color: var(--black-color-1);
}

.button-home {
	position: relative;
	width: 11rem;
	height: 3rem;
	display: flex;
	align-items: center;
	border-radius: .5rem;
	background: var(--light-blue);
}

.button-home span {
	transition: all .4s ease-in-out;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	color: var(--white-color);
}

.button-home .button-home-icon {
	display: flex;
	align-items: center;
	position: absolute;
	z-index: 2;
	left: -40%;
	transition: all .4s ease;
	color: var(--white-color);
	opacity: 0;
}

.button-home:hover .button-home-icon {
	left: calc(100% - 2rem);
	opacity: 1;
}

.button-home:hover span {
	left: 3.2rem;
}

/* Plans */
.container-home-plans {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
	background-color: var(--gray-color-1);
	padding: 5rem;
	text-align: center;
}

.contain-home-plans {
	display: flex;
	gap: 5rem;
	flex-wrap: wrap;
}

.home-plans-title {
	font-size: 2.2rem;
	font-weight: 500;
	color: var(--black-color-1);
}

.plans-text {
	max-width: 35rem;
}

.container-pack {
	background: var(--white-color);
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	transition: all .5s ease;
	position: relative;
	width: 20rem;
	position: relative;
	overflow: hidden;
}

.contain-bubbles {
	background: url('../images/bubbles.png');
	background-position: center;
	background-size: cover;
	position: absolute;
	top: -10px;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 110%;
	animation: animate 2s ease-in-out infinite;
}

@keyframes animate {
	0%, 100% {
		transform: translateY(10px);
	}
	50% {
		transform: translateY(-10px);
	}
}

.bubbles-1 {
	animation-delay: .4s;
}

.recommended-text {
	position: absolute;
	color: var(--white-color);
	top: -1rem;
	padding: .5rem .8rem;
	border-radius: .5rem;
	background: var(--light-blue);
	z-index: 1;
}

.pack-title {
	font-size: 1.5rem;
	color: var(--black-color-1);
	font-weight: 500;
	z-index: 1;
}

.container-pack.plans-icon {
	transition: all .5s ease;
}

.plans-icon {
	display: flex;
	align-items: center;
	font-size: 3rem;
	padding: 1.5rem;
	border-radius: 50%;
	background: var(--gray-color-2);
	color: var(--white-color);
	transition: all .5s ease;
	z-index: 1;
}

.container-pack:hover .plans-icon {
	background: var(--light-blue);
}

.pack-price {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	color: var(--black-color-1);
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	z-index: 1;
}

.price-number {
	font-size: 2.3rem;
	font-weight: 600;
}

.packs-button {
	padding: .8rem 1.5rem;
	color: var(--black-color-1);
	text-transform: uppercase;
	border: 1px solid var(--black-color-1);
	position: relative;
	overflow: hidden;
	cursor: pointer;
	display: inline-flex;
	transition: all .5s ease;
}

.packs-button span {
	z-index: 2;
	font-weight: 500;
}

.packs-button::after {
	content: '';
	width: 100%;
	position: absolute;
	z-index: 0;
	top: 0;
	left: calc(-100% - 2.9rem);
	border-right: 2.9rem solid transparent;
	border-bottom: 2.9rem solid var(--light-blue);
	transition: all .5s ease;
}

.packs-button:hover::after {
	left: 0;
}

.packs-button:hover {
	color: var(--white-color);
}

.pack-paragraph {
	z-index: 2;
}

.check-list-text {
	height: 2rem;
	color: var(--black-color-1);
	font-weight: 500;
	text-align: center;
}

.check-list-text span {
	font-weight: 400;
}

.recommended {
	box-shadow: 0 10px 15px #AAB;
}

.container-pack:hover {
	box-shadow: 0 10px 15px #AAB;
}

/* Stadistics */
.container-home-stadistics {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-stadistics.webp');
	background-size: cover;
	background-position: center;
	padding: 5rem 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contain-home-stadistics {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.stadistics-info {
	display: flex;
	gap: 5rem;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.contain-stadistic-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}

.stadistics-number {
	color: var(--white-color);
	font-size: 3.5rem;
	text-align: center;
}

.stadistics-text {
	color: var(--white-color);
	font-size: 1.2rem;
	text-align: center;
}

/* Home-About */
.container-home-about {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5rem;
	flex-wrap: wrap;
}

.contain-home-about {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.container-box-about {
	display: flex;
	max-width: 30rem;
	height: 30rem;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: flex-start;
	padding: 2rem;
}

.about-home-img {
	max-width: 40rem;
	display: flex;
	justify-content: flex-start;
}

.about-home-img img {
	width: 100%;
}

/* Reviews */
.container-home-reviews {
	display: flex;
	flex-direction: column;
	gap: 5rem;
	padding: 5rem 2rem;
	justify-content: center;
	align-items: center;
}

.contain-home-reviews {
	display: flex;
	gap: 3rem;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.container-testimonial-boxes {
	display: flex;
	gap: 2rem;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	max-width: 23rem;
	height: 23rem;
	padding: 2rem;
	background-color: var(--gray-color-2);
	transition: all .5s ease;
}

.container-testimonial-boxes:hover {
	background-color: var(--gray-color-1);
}

.testimonial-icon {
	font-size: 2rem;
	color: var(--white-color);
}

.testimonial-name {
	font-weight: 600;
}

.contain-google-review {
	max-width: 20rem;
}

.contain-google-review img {
	width: 100%;
}

/********************/
/*       ABOUT      */
/********************/
/* Presentation */
.container-presentation-about {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.contain-presentation-about {
	background: url('../images/banner-about.webp');
	background-position: center;
	background-size: cover;
	height: 40vh;
}

.presentation-about {
	padding: 5rem 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	gap: 3rem;
	width: 100%;
}

.contain-presentation {
	display: flex;
	flex-direction: column;
}

.presentation-title {
	font-size: 3rem;
	color: var(--black-color-1);
}

.aboutus-text {
	color: var(--black-color-1);
	font-size: 1.1rem;
	max-width: 40rem;
	text-align: center;
}

/* Mision-Vision-Values */
.container-mision-vision {
	background: url('../images/banner-mvv.webp');
	background-size: cover;
	background-position: top;
	display: flex;
	gap: 5rem;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 8rem 2rem;
}

.mision-vision-value {
	max-width: 20rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 1rem 0;
}

.mision-vision-value h3 {
	text-transform: uppercase;
	font-size: 1.2rem;
	color: var(--black-color-1);
}

.mision-vision-img {
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
	background: var(--white-color);
	padding: .5rem;
}

.mision-vision-img img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.mision-vision-text {
	padding: 1rem;
	color: var(--black-color-1);
}

/* Information */
.container-about-information {
	display: flex;
	padding: 5rem;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.about-information-content {
	display: flex;
	gap: 5rem;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 2rem 5rem;
}

.about-information-img {
	max-width: 40rem;
}

.about-information-img img {
	width: 100%;
}

.contain-about-software {
	color: var(--black-color-1);
	display: flex;
	flex-direction: column;
	gap: 2rem;
	max-width: 35rem;
	justify-content: center;
}


/********************/
/*     POLICIES     */
/********************/
.container-policies {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	padding: 5rem 8rem;
	min-height: 53.9vh;
}

.policies-spacing {
	padding-left: 1.5rem;
}

.container-policies p {
	line-height: 1.7rem;
}

.subtitle-policies {
	font-weight: 600;
	gap: .2rem;
}

.subtitle-policies span{
	font-weight: 400;
}

.icon-policies {
	padding-right: .4rem;
	font-size: .8rem;
}

/********************/
/*      CONTACT     */
/********************/
.container-contact-contact-form {
	padding: 8rem;
}

/********************/
/*    ERROR-404     */
/********************/

.container-error404 {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	color: var(--black-color-1);
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	padding: 8rem 0 2rem;
}

.required-fields-link {
	color: var(--black-color-1);
	transition: all 0.5s ease;
	font-weight: 600;
}

.container-error404-image {
	width: 40rem;
}

.container-error404-image img {
	width: 100%;
}

.required-fields-link:hover {
	color: var(--light-blue);
}

/********************/
/*   PHONE-SCREEN   */
/********************/
@media screen and (max-width: 700px) {
	/* HOME */
	/* Plans */
	.container-home-plans {
		gap: 3rem;
	}

	/* Ecommerce */
	.container-home-ecommerce {
		display: flex;
		flex-direction: column;
		background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../images/banner-ecommerce.webp');
		background-position-x: right;
		color: var(--white-color);
		padding: 5rem 2rem;
	}

	.contain-home-ecommerce {
		width: 100%;
		gap: 3rem;
	}

	.advantage-texts {
		padding: 0;
		text-align: left;
	}

	.ecommerce-phone {
		color: var(--white-color);
	}

	.ecommerce-phone2 {
		color: var(--light-blue);
	}

	/* Stadistics */
	.stadistics-info {

	}

	/* Marketing */
	.container-home-marketing {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		padding: 5rem 2rem;
		justify-content: center;
		align-items: center;
	}

	.container-box-marketing {
		width: 100%;
	}

	/* About-Main */
	.container-about-main {
		display: flex;
		flex-direction: column;
		padding: 10rem 2rem;
		background-position-x: right;
	}

	.main-about-items {
		width: 100%;
		text-align: center;
		justify-content: center;
		align-items: center;
		gap: 3rem;
	}

	.container-box-about {
		text-align: center;
		align-items: center;
	}

	/* ABOUT */
	/* Presentation */
	.container-presentation-skills {
		display: flex;
		padding: 2rem 1.5rem;
	}

	.container-presentation {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		padding: 20rem 2rem 3rem;
	}

	.uppertitle-home {
		font-size: 2rem;
		text-align: center;
	}

	.presentation-about {
		padding: 5rem 2rem;
	}

	/* Information */
	.container-about-information {
		padding: 5rem 2rem;
	}

	.about-information-content {
		padding: 2rem 0;
	}

	/* CONTACT */
	.container-contact-contact-form,
	.container-contact-about {
		padding: 8rem 2rem;
	}

	/* POLICIES */
	.container-policies {
		padding: 5rem 2rem;
	}

	/* ERROR-404 */
	.container-error404 {
		padding: 3rem 1rem;
		text-align: center;
	}

	.container-error404 img{
		width: 100%;
	}

	.container-error404-image {
		width: 100%;
	}
}