/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--black-color-1: #03151C;
	--black-color-2: #000;
	--gray-color-1: #BECCD4;
	--gray-color-2: #B0C5CE;
	--white-color: #FFFFFF;
	--light-blue: #15A4DC;
	--facebook-color: #1877F2;
	--instagram-color: #DD2A7B;
	--linkedin-color: #0072B1;
	--pinterest-color: #BD081C;
	--youtube-color: #C4302B;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: .5rem 1.8rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*     WHATSAPP     */
/********************/
.whatsapp-button {
	position: fixed;
	bottom: 40px;
	right: 40px;
	display: flex;
	gap: 1rem;
	background-color: var(--light-blue);
	align-items: center;
	justify-content: center;
	padding: .5rem 1.5rem;
	color: var(--black-color-1);
	border-radius: 2rem;
	z-index: 10;
	transition: all .5s ease;
}

.whatsapp-button:hover {
	color: var(--white-color);
}

.whatsapp-icon {
	font-size: 1.8rem;
	display: flex;
	align-items: center;
}

/********************/
/*       FAQS       */
/********************/

.container-faq {
	padding: 0 3rem;
	width: 100%;
}

.faq-item {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	padding: .5rem;
}

.faq-button {
	font-size: 1.1rem;
	background-color: var(--light-blue);
	cursor: pointer;
	border: none;
	padding: 1rem;
	color: var(--white-color);
}

.faq-text {
	background-color: var(--black-color-1);
	padding: .5rem;
	color: var(--white-color);
}

/********************/
/*       STARS      */
/********************/
.rating_stars {
    color: #EEBF61;
    font-size: 1.2rem;
}

/********************/
/*  PARALLAX-SECT   */
/********************/

.parallax-section {
	position: relative;
	height: 80vh;
	overflow-x: hidden;
}

.parallax-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/parallax-home-1.webp');
	background-size: cover;
	background-attachment: fixed; /* Fija la imagen de fondo */
	z-index: -1; /* Coloca el fondo detrás del contenido */
}

.comercial-banner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/home-comercial.webp');
	background-size: cover;
	background-attachment: fixed; /* Fija la imagen de fondo */
	z-index: -1; /* Coloca el fondo detrás del contenido */
}

.parallax-content {
	position: relative;
	z-index: 100; /* Coloca el contenido encima del fondo */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 4rem;
	padding: 2rem 5rem;
	color: white;
	text-align: center;
}

.parallax-content h3 {
		text-transform: uppercase;
		font-size: 2.2rem;
		max-width: 50rem;
}

.parallax-content p {
		font-size: 1.3rem;
}

/********************/
/*   CONTACT-FORM   */
/********************/
.container-contact-form {
	display: flex;
	flex-direction: column;
	gap: 4rem;
	align-items: center;
	justify-content: center;
}

.container-info-contact {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2rem 5rem;
	width: 40rem;
}
.contain-contact-paragraph {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.contain-info-contact {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid var(--blue-color-2);
}

.info-contact {
	display: flex;
	gap: 1rem;
}

.contact-title {
	text-align: center;
	font-size: 2rem;
	color: var(--black-color-1);
}

.contact-info {
	color: var(--blue-color-3);
	font-size: .9rem;
}

.info-contact-text {
	font-size: .9rem;
	color: var(--blue-color-3);
}

.info-contact-link {
	color: var(--light-blue);
	font-size: 1.5rem;
}

.container-contact-social {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.contain-contact-social {
	display: flex;
	gap: .5rem;
	font-size: 2rem;
	justify-content: flex-start;
	align-items: center;
}

/* Form */
.contain-contact-form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2rem;
	background-color: var(--white-color);
	width: 35rem;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.container-contact-divider {
	display: flex;
	gap: 2rem;
}

.input-contact {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.input-contact input {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.input-contact span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 0.05em;
	transition: 0.5s;
	color: var(--black-color-1);
}

.input-contact input:valid ~ span,
.input-contact input:focus ~ span {
	color: var(--black-color-1);
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.input-contact i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background: var(--light-blue);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.input-contact input:valid ~ i,
.input-contact input:focus ~ i {
	height: 2.7rem;
}

.input-contact-msg {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.input-contact-msg textarea {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.input-contact-msg span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 0.05em;
	transition: 0.5s;
	color: var(--black-color-1);
}

.input-contact-msg textarea:valid ~ span,
.input-contact-msg textarea:focus ~ span {
	color: var(--black-color-1);
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.input-contact-msg i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background: var(--light-blue);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.input-contact-msg textarea:valid ~ i,
.input-contact-msg textarea:focus ~ i {
	height: 4rem;
}

.submit-button {
	padding: .8rem 1.5rem;
	color: var(--light-blue);
	text-transform: uppercase;
	border: 2px solid var(--light-blue);
	position: relative;
	overflow: hidden;
	cursor: pointer;
	display: inline-flex;
	background-color: var(--white-color);
	transition: all .5s ease;
}

.submited-contact-button {
	padding: .8rem 1.5rem;
	color: var(--white-color);
	text-transform: uppercase;
	border: 2px solid var(--light-blue);
	position: relative;
	overflow: hidden;
	cursor: unset;
	display: inline-flex;
	background-color: #3BC249;
	transition: all .5s ease;
}

.submit-button span {
	z-index: 2;
	font-weight: 500;
}

.submit-button::after {
	content: '';
	width: 100%;
	position: absolute;
	z-index: 0;
	top: 0;
	left: calc(-100% - 2.9rem);
	border-right: 2.9rem solid var(--white-color);
	border-bottom: 2.9rem solid var(--black-color-1);
	transition: all .5s ease;
}

.submit-button:hover::after {
	left: 0;
}

.submit-button:hover {
	color: var(--white-color);
}

/********************/
/*   WRAPPER-FORM   */
/********************/
.container-wrapper-form {
	width: 20rem;
}

.wrapper-form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
}

/********************/
/*      SWIPER      */
/********************/

.swiper {
		width: 100%;
		height: 100%;
}

.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
}

.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
}

.swiper-banner-home {
		background-color: rgba(0, 0, 0, 0.7);
		max-width: 50rem;
		color: var(--white-color);
		display: flex;
		flex-direction: column;
		gap: 2rem;
		padding: 2rem 3rem;
		align-items: center;
}

.property {
		background: url('../images/property.webp');
		background-size: cover;
		background-position: center;
}

.comercial {
		background: url('../images/comercial.webp');
		background-size: cover;
		background-position: center;
}

.surface {
		background: url('../images/surface.webp');
		background-size: cover;
		background-position: center;
}

.swiper-banner-home h3 {
		text-transform: uppercase;
		font-size: 2rem;
		font-weight: 500;
}

/********************/
/*   PHONE-SCREEN   */
/********************/
@media screen and (max-width: 700px) {
	/* CONTACT-FORM */
	.container-contact-form {
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}

	.container-info-contact {
		width: 100%;
		padding: 2rem 0;
	}

	/* Form */
	.contain-contact-form {
		width: 100%;
	}

	.container-contact-divider {
		flex-direction: column;
	}

	/* PARALLAX */
	.parallax-section {
		min-height: 80vh;
	}
}