/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--black-color-1: #03151C;
	--black-color-2: #000;
	--gray-color-1: #BECCD4;
	--gray-color-2: #B0C5CE;
	--white-color: #FFFFFF;
	--light-blue: #15A4DC;
	--facebook-color: #1877F2;
	--instagram-color: #DD2A7B;
	--linkedin-color: #0072B1;
	--pinterest-color: #BD081C;
	--youtube-color: #C4302B;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: .5rem 1.8rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*     SERVICES     */
/********************/
/* Banner */
.container-comercial-banner {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.contain-comercial-banner {
	background: url('../images/banner-comercial.webp');
	background-position: bottom;
	background-size: cover;
	height: 40vh;
}

.contain-residential-banner {
	background: url('../images/banner-residential.webp');
	background-position: center;
	background-size: cover;
	height: 40vh;
}

.container-comercial-service {
	padding: 5rem 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	gap: 3rem;
	width: 100%;
}

.container-comercial-service h2 {
	font-size: 3rem;
	font-weight: 500;
	color: var(--black-color-1);
}

.container-comercial-service p {
	max-width: 40rem;
	text-align: center;
}

/* Comercial-Cleaning */
.container-comercial-items {
	padding: 5rem 0;
	display: flex;
	flex-wrap: wrap;
	gap: 4rem;
	align-items: flex-start;
	justify-content: center;
}

.contain-comercial-items {
	background: url('../images/comercial-cleaning.webp');
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	gap: 4rem;
	justify-content: center;
	align-items: center;
	max-width: 50rem;
	width: 100%;
}

.comercial-item {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	padding-top: 2rem;
}

.comercial-item-title {
	background-color: rgba(21, 164, 220, 0.7);
	text-transform: uppercase;
	color: var(--white-color);
	font-size: 1.8rem;
	padding: .2rem 1rem;
}

/* Standard-Cleaning */
.container-standard-service {
	display: flex;
	background: url('../images/banner-standard.webp');
	padding: 5rem 10rem;
	flex-direction: column;
	align-items: flex-end;
	background-position: center;
	background-size: cover;
	gap: 4rem;
}

.standard-service-title {
	font-size: 3rem;
	color: var(--black-color-1);
	padding-right: 3rem;
}

/* Deep-Cleaning */
.container-deep-service {
	display: flex;
	background: url('../images/banner-deep.webp');
	padding: 5rem 10rem;
	flex-direction: column;
	background-position: center;
	background-size: cover;
	gap: 4rem;
}

.deep-service-title {
	font-size: 3rem;
	color: var(--black-color-1);
	padding-left: 3rem;
}

.contain-deep-service {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	position: relative;
	max-width: 45rem;
	background-color: rgba(21, 164, 220, 0.8);
	border-radius: 2rem;
	padding: 2rem 3rem;
}

.contain-deep-info {
	display: flex;
	flex-direction: column;
	gap: .2rem;
	z-index: 1;
}

.contain-deep-info p {
	font-size: .9rem;
}

.deep-info-title {
	text-transform: uppercase;
	font-size: 1.4rem;
	color: var(--white-color);
}

/* FAQs */
.container-faqs {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	padding: 3rem 0;
}

/*********************/
/*    PHONE-SCREEN   */
/*********************/

@media screen and (max-width: 700px) {
	/* SERVICES */
	.container-comercial-service {
		padding: 5rem 2rem;
	}

	.container-comercial-items {
		padding-left: 2rem;
	}
}